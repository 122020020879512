import React from 'react';
import Button from 'react-bootstrap/Button';
import { MDBIcon } from 'mdb-react-ui-kit';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ContactIcons = () => {
  return (
    <div className="social-icons">
      <Button className='m-1 social-btn' style={{ backgroundColor: '#3b5998' }} target="_blank"
        href="https://www.facebook.com/profile.php?id=100088981046198">
        <MDBIcon fab icon='facebook-f' />
      </Button>

      {/* <Button className='m-1 social-btn' style={{ backgroundColor: '#dd4b39' }} href='#'>
        <MDBIcon fab icon='google' />
      </Button> */}

      <Button className='m-1 social-btn' style={{ backgroundColor: '#ac2bac' }} target="_black"
        href='https://instagram.com/modern.mithai?igshid=YmMyMTA2M2Y='>
        <MDBIcon fab icon='instagram' />
      </Button>

      <Button className='m-1 social-btn' style={{ backgroundColor: '#0082ca' }} target="_blank"
        href='https://www.linkedin.com/company/modern-mithai/'>
        <MDBIcon fab icon='linkedin-in' />
      </Button>

      <Button className='m-1 social-btn' style={{ backgroundColor: '#25d366' }} target="_blank"
        href='https://wa.me/447435765494'>
        <MDBIcon fab icon='whatsapp' />
      </Button>
    </div>
  );
}

export default ContactIcons;