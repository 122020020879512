import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/images/logo.png';


const Footer = () => {
  return (
    <Navbar className="footer-bg" variant="dark" expand="lg" >
        <Container>
        
        <Nav className="justify-content-center flex-grow-1 pe-3 footer-text">
            <Nav.Link className="nav-links" href="/">Home</Nav.Link>
            <Nav.Link className="nav-links" href="/about">About</Nav.Link>
            <Nav.Link className="nav-links" href="/contact">Contact</Nav.Link>
        </Nav>
        
        <Nav.Link href="/"><img src={logo} width="60" height="60" href="/" className="d-inline-block align-top logo"
          alt="React Bootstrap logo"/></Nav.Link>
        </Container>
    </Navbar>
  );
}

export default Footer;