import Image from 'react-bootstrap/Image';
import headerLogo from '../assets/images/Momi-logo.jpg';
import section1Img from '../assets/images/section1Img.jpg';
import section2Img from '../assets/images/section2Img.jpg';

const Home = () => {
    return (
        <div className="home">
            <div className="home-top">
                <div className="home-top-dim">
                    <div className="home-top-logo">
                        <Image className="home-top-logo-dim" src={headerLogo} alt="Logo of the website"/>
                    </div>
                    <div className="home-top-heading">
                        <h1 className="home-top-heading-dim">WELCOME</h1>
                        <p className="home-top-paragraph-dim">to the world of Indian Delicacy</p>
                    </div>
                </div>
            </div>

            <div className="home-section1">
                <div className="section1-img">
                    <Image className="section1-img-dim" src={section1Img} alt="Cova sweet image"/>
                </div>
                <div className="section1-heading">
                    <h1 className="section1-heading-dim">Indian Sweets At Your Convenience</h1>
                    <p className="section1-paragraph-dim">We at Morden mithai, are delighted to introduce
                        the authentic taste of traditional Indian sweets to the United Kingdom.
                        Our delicacies are prepared with lots of love and fresh quality ingredients.
                        We aim to bring mo.mi to your nearest store.
                    </p>
                    <p className="section1-paragraph-dim">Pick pop and walk.</p>
                </div>
            </div>

            <div className="home-section2">
                <div className="section2-heading">
                    <h1 className="section2-heading-dim">Star of the Show</h1>
                    <p className="section2-paragraph-dim">Kova is a staple dish for a majority of households
                        in India during celebrations. Modern Mithai wants to bring this celebration to the people of U.K,
                        rebranded as MoonBite.
                    </p>
                </div>
                <div className="section2-img">
                    <Image className="section2-img-dim" src={section2Img} alt="Cova sweet image"/>
                </div>
            </div>
        </div>
    );
}

export default Home;