import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/Header.css';
import './styles/Home.css';
import './styles/Nameste.css';
import './styles/About.css';
import './styles/Contact.css'
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import Nameste from './components/Namaste';

function App() {
  return (
    <div className="">
      <BrowserRouter>
          <Header />
          <Nameste />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/contact" exact element={<Contact />} />
          </Routes>

          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
