import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/images/logo.png';
import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const previousScrollPosition = useRef(0);

  useEffect(() => {
    function handleEvent(event) {
      if (event.type === "scroll") {
        if (window.pageYOffset < previousScrollPosition.current || window.pageYOffset <=50) {
          setShowNavbar(true);
        } else {
          setShowNavbar(false);
        }
        previousScrollPosition.current = window.pageYOffset;
      } else if (event.type === "touchmove") {
        if (window.pageYOffset <= 50) {
          setShowNavbar(true);
        } else {
          setShowNavbar(false);
        }
      }
    }
    window.addEventListener('scroll', handleEvent);
    window.addEventListener('touchmove', handleEvent);
    return () => {
      window.removeEventListener('scroll', handleEvent);
      window.removeEventListener('touchmove', handleEvent);
    };
  }, []);

  const className = classnames('navbar-bg', `header ${showNavbar ? 'slide-down' : 'slide-up'}`);

  return (
    <Navbar fixed="top" className={className} variant="dark" expand="lg" >
        <Container>
        <Nav.Link href="/"><img src={logo} width="60" height="60" href="/" className="d-inline-block align-top logo"
          alt="React Bootstrap logo"/></Nav.Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="justify-content-center flex-grow-1 pe-3 nav-text">
            <Nav.Link className="nav-links" href="/">Home</Nav.Link>
            <Nav.Link className="nav-links" href="/about">About</Nav.Link>
            <Nav.Link className="nav-links" href="/contact">Contact</Nav.Link>
        </Nav>
        </Navbar.Collapse>
        </Container>
    </Navbar>
  );
}

export default Header;