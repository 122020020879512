import { useState } from "react";
import { useRef } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ContactIcons from "./ContactIcons";
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [error, setError] = useState ();
    const inputRef = useRef();
    const form = useRef();
    const [msg, setMsg] = useState ("");
    const [fullname, setFullname] = useState ();
    const [email, setEmail] = useState ();
    const [message, setMessage] = useState ();

    const SubmitHandler = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_yxbq2qi', 'template_5mzcxnh', form.current, 'pppcM87TOE-KPKvZ9')
            .then((result) => {
                console.log(result.text);
                setMsg(<i>"Message sent successfully! Please refresh the page to send another message."</i>);
                setFullname("");
                setEmail("");
                setMessage("");
            }, (error) => {
                setError(error.text);
            });
    };

    return (
        <div className="contact">
            <div className="contact-heading">
                <h1 className="contact-heading-dim">Contact Us</h1>
            </div>

            <div className="contact-form">
                <div className="form-dim">
                    <Form className="contact-form-dim" ref={form} onSubmit={SubmitHandler}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="fullName">Name</Form.Label>
                            <Form.Control type="text" value={fullname} name="user_name" id="fullName" className="form-input" placeholder="Enter your fullname" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="email">Email address</Form.Label>
                            <Form.Control type="email" value={email} name="user_email" id="email" className="form-input" placeholder="Enter your email" required />
                            <p><small style={{ color: "#873131" }}>{error && error}</small></p>
                            <Form.Text className="text-muted"><sup>We'll never share your email with anyone else.</sup>
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-5">
                            <Form.Label htmlFor="message">Message</Form.Label>
                            <Form.Control as="textarea" value={message} name="user_message" id="message" className="form-input" ref={inputRef} placeholder="Enter your message" required />
                        </Form.Group>
                        <div className="contact-submit-btn-dim">
                        <Button className="contact-submit-btn mb-3" type="submit" size="lg">Submit</Button>
                        <p style={{ color: "#ab8c56" }}><b>{msg && msg}</b></p>
                        </div>
                    </Form>
                </div>

                <div className="contact-info">
                    <div className="info-text">
                        <h1 className="info-heading">You can also reach on:</h1>
                        <p className="info-paragraph"><b>Phone: </b><span className="info-para">+44 7435 765494</span></p>
                        <p className="info-paragraph"><b>Email: </b><span className="info-para">modernmithai@gmail.com</span></p>
                    </div>
                    <div className="contact-icons">
                        <p className="contact-icons-paragraph"><i>Follow us on:</i></p>
                        <ContactIcons />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;