import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Card from 'react-bootstrap/Card';
import aboutImg from "../assets/images/white-cova.jpg";
import team1 from "../assets/images/prithvi-img.jpeg";
import product1 from "../assets/images/product1.jpeg";
import product2 from "../assets/images/product2.jpg";
import product3 from "../assets/images/product3.jpeg";
import product4 from "../assets/images/product4.jpeg";

const About = () => {
    return (
        <div className="about">
            <div className="about-heading">
                <h1 className="about-heading-dim">Meet Modern Mithai</h1>
                <p className="about-paragraph-dim">Welcome to Modern Mithai, aka. MO.MI., a startup specializing in
                    authentic and delicious Indian sweets. Our company is based in United Kingdom, we are dedicated
                    to bring the rich and diverse flavors of India to our customers. MO.MI. starts it's journey
                    with MoonBite, originally known as Kova, which is south India's most popular desert made of milk.
                    We bring you a tried, tested and proved recipe, over generations all around India.
                </p>
            </div>

            <div className="about-btns">
                <div className="about-buttons">
                    <Button className="about-btn-dim" href="#story">Our Story</Button>
                    <Button className="about-btn-dim" href="#plan">Our Plan</Button>
                    <Button className="about-btn-dim" href="#products">Our Products</Button>
                    <Button className="about-btn-dim" href="#team">Our Team</Button>
                </div>
            </div>

            <div className="about-img">
                <Image className="about-img-dim" src={aboutImg} alt="Cova sweet image"/>
                <p className="about-paragraph-dim about-img-para"><i><b>MoonBite (Kova)</b></i></p>
            </div>

            <div className="about-heading" id="story">
                <h1 className="about-heading-dim">Our Story</h1>
                <p className="about-paragraph-dim">The idea for this company was born out
                    of a love for traditional Indian sweets and a desire to share that love
                    with others. We realized that while these deserts may be hard to find,
                    they are truly mouthwatering and worth discovering. So, MO.MI. set
                    out on a mission to introduce these delicacies to a wider audience, not
                    just to fill a gap in the market, but to bring joy and happiness to
                    people's lives through these sweets. MO.MI. is a
                    company which is not just about selling desserts, it's about creating memories
                    and happiness through food.
                </p>
            </div>

            <div className="about-heading" id="plan">
                <h1 className="about-heading-dim">Our Plan</h1>
                <p className="about-paragraph-dim">The final goal of Mo.Mi.
                    is to be easily accessible to the customers no matter where they
                    are shopping. The company aims to make its mark in the industry
                    by offering a unique take on traditional Indian sweets.These sweets have been
                    loved for centuries by more than 1.3 billion people.There are more than a 100 Indian
                    origin sweets that have the potential to be the UK's favourite choice. We want to
                    make a company that utilises this potential by introducing the diverse consumers of the UK, to
                    the world of Indian sweets.
                </p>
            </div>

            <div className="about-heading" id="products">
                <h1 className="about-heading-dim">Our Products</h1>
                <p className="about-paragraph-dim">The company takes off with a milk-based sweet called "Kova",
                    rebranded as "MoonBite", that will launch in Liverpool and expand all over the country.
                    Moonbite is made with the highest quality of milk, carefully simmered for hours,
                    to get the perfect texture. We offer MoonBite in two different varients, one that
                    can go as a snack and other can be used as a bread spread.
                </p>
                <p className="about-paragraph-dim">Great things come for people who wait,
                    but you don't have to wait very long for us to bring you more exciting
                    and exotic sweets from India. Psst! We'll let you in on a secret. Our potential product line might look like:
                </p>
                <div className="products-imgs">
                    <Card className="about-card" style={{ width: '18rem', height: '23rem' }}>
                        <Card.Img variant="top" src={product1} style={{ width: '100%', height: '80%' }} />
                        <Card.Body>
                            <Card.Title>Gulab Jamun</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="about-card" style={{ width: '18rem', height: '23rem' }}>
                        <Card.Img variant="top" src={product2} style={{ width: '100%', height: '80%' }} />
                        <Card.Body>
                            <Card.Title>Bombay Halwa</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="about-card" style={{ width: '18rem', height: '23rem'}}>
                        <Card.Img variant="top" src={product3} style={{ width: '100%', height: '80%' }} />
                        <Card.Body>
                            <Card.Title>Rasgulla</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="about-card" style={{ width: '18rem', height: '23rem' }}>
                        <Card.Img variant="top" src={product4} style={{ width: '100%', height: '80%' }} />
                        <Card.Body>
                            <Card.Title>Motichur</Card.Title>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className="about-team-heading" id="team">
                <h1 className="about-heading-dim">Our Team</h1>
                <div>
                    <Card className="about-card" style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={team1} />
                        <Card.Body>
                            <Card.Title>Prithvi Arakatavemula</Card.Title>
                            <Card.Text>
                            FOUNDER / CEO
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default About;